import axios from "axios";
import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import BackButton from "../../components/botãodevoltar";

const ServiconaoterminadoC = () => {
  const id = localStorage.getItem("userId");
  const [pedidoData, setPedidoData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPedido = async () => {
      try {
        const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/pedidos-aceitosc/${id}`);
        setPedidoData(response.data);
      } catch (error) {
        console.error('Erro ao buscar pedidos:', error);
      }
    };

    fetchPedido();
  }, [id]);

  const formatDate = (dateString) => {
      // Parse a string ISO no formato 'YYYY-MM-DD' para um objeto Date
      try {
          const date = parseISO(dateString);
          return format(date, 'dd/MM/yyyy', { locale: ptBR });
      } catch (error) {
          console.error('Erro ao formatar data:', error);
          return dateString; // Retorne a string original em caso de erro
      }
  };

  const handleAcceptOrder = async (order) => {
    try {
      // Verifica o status do pedido
      if (order.status === 'Concluído') {
        // Redireciona para a página de pagamento se o status for "Concluído"
        navigate(`/payment-gateway/${order.id}`);
      } else if (order.status === 'Aceito') {
        // Continua o fluxo normal se o status for "Aceito"
        navigate(`/serviçoemandamento/${order.id}`);
      }else if (order.status === 'Pendente'){
        navigate(`/aguardando-aceitacao/${order.id}`);
      }
    } catch (error) {
      console.error('Erro ao aceitar pedido de serviço:', error);
    }
  };

  return (
    <main>
      <h1>Serviços não finalizados</h1>
      <BackButton/>
      <div className="services-listp">
        {pedidoData.length > 0 ? (
          pedidoData.map((order, index) => (
            <div key={index} className="service-item">
              <h2>{order.nome_servico}</h2>
              <p>Categoria: {order.categoria}</p>
              <p>Preço: {order.preco}</p>
              <p>Cliente: {order.cliente}</p>
              <p>Endereço: {order.endereco}, {order.numero}</p>
              <p>Data: {formatDate(order.date)}</p>
              <p>Hora: {order.time}</p>
              <button onClick={() => handleAcceptOrder(order)}>Visualizar</button>
            </div>
          ))
        ) : (
          <p>Nenhum serviço não finalizado disponível.</p>
        )}
      </div>
    </main>
  );
};

export default ServiconaoterminadoC;
