import axios from "axios"
import { Field, Form, Formik } from "formik"

export const CreateAccountAdmin = () =>{
    const handleSubmit = async (values) => {
        try {
            const response = await axios.post("https://appdev.chamaseuze.chamaoseuze.com.br/api/createUserMaster", values)
            console.log(response)
        } catch (error) {
            console.error(error.message || error.data)
        }
    }
    return(
        <>
        <Formik
            initialValues={{
                name: '',
                email: "",
                password: "",
            }}
            onSubmit={handleSubmit} 
        >
            <Form>
                <Field name="name" type="text" /> 
                <Field name='email' type='email' placeholder="Digite seu email"/>
                <Field name='password' type='password' placeholder="Digite sua senha"/>
                <button type="submit">Salvar</button>
            </Form>

        </Formik>
        </>
    )
}



