import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import './serviçosproximos.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import BackButton from '../../components/botãodevoltarP';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const socket = io('https://appdev.chamaseuze.chamaoseuze.com.br/');

const NearbyServices = () => {
    const [newOrders, setNewOrders] = useState([]);
    const [prestadorNome, setPrestadorNome] = useState('');
    const navigate = useNavigate();
    const id = localStorage.getItem("userId")// Pegando o ID do prestador

    useEffect(() => {
        const fetchPendingOrders = async () => {
            try {
                const response = await axios.get('https://appdev.chamaseuze.chamaoseuze.com.br/pedidos-pendentes');
                setNewOrders(response.data);
            } catch (error) {
                console.error('Erro ao buscar pedidos pendentes:', error);
            }
        };

        const fetchPrestadorNome = async () => {
            try {
                const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/prestador/${id}`);
                setPrestadorNome(response.data.nomeCompleto);
                console.log(response.data.nomeCompleto)
            } catch (error) {
                console.error('Erro ao buscar nome do prestador:', error);
            }
        };

        fetchPendingOrders();
        fetchPrestadorNome();

        socket.on('novo-pedido-servico', (order) => {
            setNewOrders((prevOrders) => [...prevOrders, order]);
        });

        return () => {
            socket.off('novo-pedido-servico');
        };
    }, [id]);


   const formatDate = (dateString) => {
       // Parse a string ISO no formato 'YYYY-MM-DD' para um objeto Date
       try {
           const date = parseISO(dateString);
           return format(date, 'dd/MM/yyyy', { locale: ptBR });
       } catch (error) {
           console.error('Erro ao formatar data:', error);
           return dateString; // Retorne a string original em caso de erro
       }
   };

    const handleAcceptOrder = async (order) => {
        try {
            const response = await axios.post(`https://appdev.chamaseuze.chamaoseuze.com.br/verificar-pedido`, {
                idprestador: id,
                date: order.date,
                time: order.time
            });

            // Verificando se o pedido já foi aceito
            if (response.data.exists) {
                alert('Você já tem um pedido aceito para essa data e hora.');
            } else {
                await axios.post('https://appdev.chamaseuze.chamaoseuze.com.br/atualizar-pedido', {
                    id: order.id,
                    status: 'Aceito',
                    provider: prestadorNome,
                    idprestador: id
                });
                navigate(`/servico-em-andamento/${order.id}`);
            }
        } catch (error) {
            console.error('Erro ao aceitar pedido de serviço:', error);
        }
    };

    const handleRejectOrder = async (order) => {
        try {
            await axios.post('https://appdev.chamaseuze.chamaoseuze.com.br/atualizar-pedido', {
                id: order.id,
                status: 'Recusado',
            });
            setNewOrders(newOrders.filter((o) => o.id !== order.id));
        } catch (error) {
            console.error('Erro ao recusar pedido de serviço:', error);
        }
    };

    return (
        <div className="nearby-services-container">
            <BackButton /> <Link to={`/servicosemandamentos/${id}`} className='botaoparaotatela'>Não Finalizados</Link>
            <h1>Serviços Próximos</h1>
            <div className="services-listp">
                {newOrders.length > 0 ? (
                    newOrders.map((order, index) => (
                        <div key={index} className="service-item">
                            <h2>{order.nome_servico}</h2>
                            <p>Categoria: {order.categoria}</p>
                            <p>Preço: {order.preco}</p>
                            <p>Cliente: {order.cliente}</p>
                            <p>Endereço: {order.endereco}, {order.numero}</p>
                            <p>Data: {formatDate(order.date)}</p>
                            <p>Hora: {order.time}</p>
                            <button onClick={() => handleAcceptOrder(order)}>Aceitar</button>
                            <button onClick={() => handleRejectOrder(order)}>Recusar</button>
                        </div>
                    ))
                ) : (
                    <p>Nenhum serviço próximo disponível.</p>
                )}
            </div>
            
        </div>
    );
};

export default NearbyServices;