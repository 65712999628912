// src/components/ConsumersManagement.js

import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import './ConsumersManagement.css'; // Arquivo CSS para estilos
import axios from 'axios';
import { BackButtonAdmin } from '../components/BackButton.jsx/BackButton';
const ConsumersManagement = () => {
  const [consumers, setConsumers] = useState([]);
  const [selectedConsumer, setSelectedConsumer] = useState(null);

  useEffect(() => {
    const fetchConsumers = async () => {
      try {
        const response = await axios.get("https://appdev.chamaseuze.chamaoseuze.com.br/getAllUsers");
        console.log(response.data);
        setConsumers(response.data);
      } catch (error) {
        console.error(error.message || error.data)
      }
    }
    fetchConsumers();
  }, []);

  const handleImport = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      // Adicionar os dados importados à lista de consumidores
      setConsumers((prevConsumers) => [
        ...prevConsumers,
        ...jsonData.map((item, index) => ({ id: prevConsumers.length + index + 1, ...item })),
      ]);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleEdit = () => {
    if (!selectedConsumer) {
      alert('Selecione um consumidor para editar.');
      return;
    }
    // Função para editar um consumidor
    console.log(`Editar consumidor com ID: ${selectedConsumer.id}`);
  };



  const handleSelectConsumer = (id) => {
    setSelectedConsumer(consumers.find((consumer) => consumer.id === id));
  };

  return (
    <div className="consumers-management">
     
      <header>
        <h1 style={{color:'white'}}>Gestão de Consumidores</h1>
      </header>
      <main>
      <BackButtonAdmin/>
        <section className="consumers-list">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {consumers.map((consumer) => (
                <tr
                  key={consumer.idusuarios}
                  className={selectedConsumer?.id === consumer.id ? 'selected' : ''}
                  onClick={() => handleSelectConsumer(consumer.id)}
                >
                  <td>{consumer.idusuarios}</td>
                  <td>{consumer.nome}</td>
                  <td>{consumer.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>
    </div>
  );
};

export default ConsumersManagement;
