import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './AwaitingAcceptance.css';
import io from 'socket.io-client';
import BackButton from '../../components/botãodevoltar';

const socket = io('https://appdev.chamaseuze.chamaoseuze.com.br/'); // Substitua pela URL correta do seu servidor

const AguardandoAceitacao = () => {
  const { id } = useParams(); // Pega o ID da URL
  const [pedido, setPedido] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isModal,setIsModal] = useState(false)
  const userId = localStorage.getItem("userId");


  const backToHome = () =>{
    navigate(`/MapaPage/${userId}`);
  }


  const cancelOrder = async ()=>{
    try {
      const respose = axios.post(`https://appdev.chamaseuze.chamaoseuze.com.br/cancelOrder/${id}`)
      
      if(respose){
        setIsModal(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const fetchPedido = async () => {
      try {
        const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/pedidos/${id}`);
        setPedido(response.data);
      } catch (err) {
        setError('Erro ao carregar o pedido.');
      } finally {
        setLoading(false);
      }
    };
    fetchPedido();
  }, []);

  useEffect(() => {
    socket.on('status-pedido-atualizado', ({ id: pedidoId, status }) => {
      if (pedidoId === Number(id) && status === 'Aceito') {
        navigate(`/serviçoemandamento/${id}`);
      }
    },[]);
    return () => {
      socket.off('status-pedido-atualizado');
    };
  }, [id]);

  if (loading) {
    return <div className="awaiting-acceptance">Carregando...</div>;
  }

  if (error) {
    return <div className="awaiting-acceptance">{error}</div>;
  }

  if (!pedido) {
    return <div className="awaiting-acceptance">Nenhum pedido encontrado.</div>;
  }

  return (
    <div className="awaiting-acceptance">
      <BackButton/>

      {isModal &&(
        <div className="modal">
          <div className="modal-content">
            <h1>Pedido Cancelado com sucesso!</h1>
            <button onClick={backToHome}>Ok</button>
          </div>
        </div>
      )}
      <h1>Aguardando Aceitação do Pedido</h1>
      <div className="pedido-details">
        <p><strong>ID do Pedido:</strong> {pedido.id}</p>
        <p><strong>Nome do Serviço:</strong> {pedido.nome_servico}</p>
        <p><strong>Categoria:</strong> {pedido.categoria}</p>
        <p><strong>Preço:</strong> {pedido.preco}</p>
        <p><strong>Cliente:</strong> {pedido.cliente}</p>
        <p><strong>Status:</strong> {pedido.status}</p>
        <button onClick={cancelOrder}>Cancelar</button>
      </div>
      
    </div>
  );
};

export default AguardandoAceitacao;