import React, { useEffect, useState, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import 'leaflet.awesome-markers';
import '@fortawesome/fontawesome-free/css/all.css';
import './principal.css';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import axios from "axios";

const socket = io('https://appdev.chamaseuze.chamaoseuze.com.br/');

const Principal = () => {
  const id = localStorage.getItem("userId");
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const [userPosition, setUserPosition] = useState(null);
  const [existUserId, setExistUserId] = useState(false);
  const [certification, setCertification] = useState(localStorage.getItem("certificado") || "true");

  const handleAlterCertification = () =>{
    localStorage.setItem("certificado", "false");
    setCertification("false")
  }

  function perfil() {
    navigate(`/Profile/${id}`);
  }
  function faturamento() {
    navigate(`/faturamento/${id}`);
  }
  function Agenda() {
    navigate(`/AgendaPrestador/${id}`);
  }
  function buscar() {
    navigate(`/ServiçosProximos/${id}`);
  }

  useEffect(() => {
    const fetchUserIdExist = async () => {
      try {
        const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/prestador/${id}`); // Substitua pelo endpoint reais
        if (response.data.userIdMercadoPago === null) {
          setExistUserId(true)
        }
      } catch (error) {
        console.error("Erro interno", error.data || error.message);
      }
    }
    fetchUserIdExist();
  }, [])

  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const pos = await getCurrentLocation();
        const coords = [pos.coords.latitude, pos.coords.longitude];
        setUserPosition(coords);


        // Enviar localização do prestador para o servidor
        socket.emit('update-location', {
          id, // Usando o ID da URL
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude
        });
      } catch (error) {
        console.error('Erro ao obter localização do prestador:', error.message);
      }
    };

    fetchUserLocation();

    return () => {
      socket.off('location-updated');
    };
  }, [id]);

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      } else {
        reject(new Error('Geolocalização não suportada pelo navegador.'));
      }
    });
  };

  useEffect(() => {
    if (userPosition) {
      if (mapRef.current) {
        mapRef.current.off();
        mapRef.current.remove();
        mapRef.current = null;
      }

      mapRef.current = L.map('map').setView(userPosition, 13);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OpenStreetMap contributors'
      }).addTo(mapRef.current);

      const userIcon = L.AwesomeMarkers.icon({
        icon: 'fa-location-arrow',
        markerColor: 'blue',
        prefix: 'fa'
      });

      L.marker(userPosition, { icon: userIcon }).addTo(mapRef.current)
        .bindPopup('Você está aqui!')
        .openPopup();
    }
  }, [userPosition]);

  const timestamp = Date.now()

  return (
    <div className="map-container">
      {existUserId && (
        <div className="modal" style={{ zIndex: 10000, }}>
          <div className="modal-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40%', width: '40%' }}>
            <a className='butn' style={{ color: 'orange', backgroundColor: 'transparent', width: 'auto' }} href='https://auth.mercadopago.com.br/authorization?client_id=6818705726887839&response_type=code&platform_id=mp'>Conectar com o mercado pago</a>
          </div>
        </div>
      )}
      {certification === 'true' && (
        <div className="modal" style={{ zIndex: 10000 }}>
          <div className="modal-content" style={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', height: '40%', width: '40%' }}>
            <h1>Você teria interesse em novos certificados?</h1>
            <div style={{display:'flex', gap:"5px"}}>
            <button>Sim</button>
            <button onClick={handleAlterCertification}> Não</button>
            </div>
          </div> 
        </div>
      )}
      <div id="map" style={{ height: '90dvh' }}></div>
      <div className='content'>
        <button className='butn' onClick={Agenda}>
          <i className="fas fa-calendar-alt"></i> Agenda
        </button>
        <button className='butn' onClick={buscar}>
          <i className="fas fa-map-marker-alt"></i> Serviços Próximos
        </button>
        <button className='butn' onClick={perfil}>
          <i className="fas fa-user"></i> Perfil
        </button>
        <button className='butn' onClick={faturamento}>
          <i className="fas fa-dollar-sign"></i> Finanças
        </button>


      </div>
    </div>
  );
};

export default Principal;
