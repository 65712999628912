import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styles from "./login.module.css";
import logo from "./logo-ze.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const Login = () => {
  const [isModal, setIsModal] = useState(false)
  const [isModal2, setIsModal2] = useState(false)
  const [isModal3, setIsModal3] = useState(false)
  const navigate = useNavigate();

  const SwitchModal = () =>{
    setIsModal((prev) => !prev)
  }

  const forgetPassword = () =>{
    try {
      setIsModal2(true)
    } catch (error) {
      console.error(error.response.data.message)
    }
  }

  function cadast() {
    navigate("/cadastro");
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post('https://appdev.chamaseuze.chamaoseuze.com.br/login', values);
  
      if (response.data.id) {
        localStorage.setItem('userId', response.data.id);
        localStorage.setItem('authToken', response.data.token)
        navigate(`/MapaPage/${response.data.id}`);
      } else {
        console.error('ID do consumidor não encontrado na resposta.');
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      setIsModal(true)
    } finally {
      setSubmitting(false);
    }
  };

  const handleForgetPassword = async (value) =>{
    console.log(value)
    try {
      await axios.post('https://appdev.chamaseuze.chamaoseuze.com.br/forgetPasswordUser', value)
      setIsModal3(true)
    } catch (error) {
      console.error(error)
    }
  }
  

  return (
    <div className={styles["login-container"]}>
      {isModal2 &&(
        <div className="modal">
          <div className="modal-content">
            <Formik
             initialValues={{}}
             onSubmit={handleForgetPassword}
            >
              <Form style={{display:'flex', justifyContent:'center', alignItems:'center', gap:'15px'}}>
                <Field name='emailForgetPassword' placeholder="Digite seu Email"/>
                <button style={{width:'50%'}}>Enviar</button>
              </Form>
            </Formik>
          </div>
        </div>
      )}

      {isModal3 && (
        <div className="modal">
        <div className="modal-content">
          <h2>Enviamos um email a você!</h2>
        </div>
      </div>
      )}
      <div className={styles["login"]}>
        <div className={styles["logo-login"]}>
          <img src={logo} alt="Logo" />
        </div>
        <div className={styles["login-content"]}>
          {isModal &&(
            <div className="modal">
              <div className="modal-content" style={{color:'black'}}>
                <h3>Verifique seu email ou sua senha!</h3>
                <h3>Ou</h3>
                <h3>Se Você não é cadastrado clique no botão cadastrar</h3>
                <button onClick={SwitchModal}>Ok</button>
              </div>
            </div>
          )}
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={handleSubmit}
          >
            <Form className={styles["form-group"]}>
              <Field
                name="email"
                className={`${styles.input} ${styles["texto-laranja"]}`}
                placeholder="Digite Seu email!"
                id="username"
                type="email"
              />
              <ErrorMessage
                name="email"
                component="span"
                className={styles.formerro}
              />
              <Field
                name="password"
                className={`${styles.input} ${styles["texto-laranja"]}`}
                placeholder="Digite Sua senha!"
                id="password"
                type="password"
              />
              <ErrorMessage
                name="password"
                component="span"
                className={styles.formerro}
              />
              <div className={styles["entrar-cadastrar"]}>
                <button
                  className={`${styles["botao-login"]}`}
                  id="botaoLogin"
                  type="submit"
                >
                  Entrar
                </button>
                <button
                  className={`${styles["botao-login"]}`}
                  id="botaoCadastro"
                  onClick={cadast}
                  type="button" // Change to type="button" to prevent form submission
                >
                  Cadastrar
                </button>
                
              </div>

              {/* <div className={styles.icons}>
                <button
                  className={`${styles["botao-padrao"]}`}
                  id="btnFacebook"
                >
                  <FontAwesomeIcon icon={faFacebookF} size="lg" />
                </button>
                <button
                  className={`${styles["botao-padrao"]}`}
                  id="btnConhecer"
                >
                  <FontAwesomeIcon icon={faUser} size="lg" />
                </button>
              </div> */}
            </Form>
          </Formik>
          <button id="btnEsqueciSenha" onClick={forgetPassword} className={`${styles["btnEsqueciSenha"]}`}>Esqueci minha senha</button>
        </div>
      </div>
    </div>
  );
};

export default Login;
