import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { io } from 'socket.io-client';
import L from 'leaflet'; // Import Leaflet
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import './ServiceInProgress.css';

const ServiceInProgress = () => {
  const [userPosition, setUserPosition] = useState({ lat: 0, lon: 0 });
  const { id } = useParams();
  const [service, setService] = useState(null);
  const [price, setPrice] = useState(0);
  const [status, setStatus] = useState(null)
  const [coordUser, setCoordUser] = useState({ lat: 0, lon: 0 });
  const navigate = useNavigate();
  const socket = io('https://appdev.chamaseuze.chamaoseuze.com.br/');
  const [tempo, setTempo] = useState(0);

  // Substitua pela sua chave da API do GraphHopper
  const graphhopperKey = '1327d45d-9b26-436c-900e-868ebaefb326';




  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      } else {
        reject(new Error('Geolocalização não suportada pelo navegador.'));
      }
    });
  };

  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const pos = await getCurrentLocation();
        const coords = [pos.coords.latitude, pos.coords.longitude];
        setUserPosition({ lat: coords[0], lon: coords[1] });



        // Enviar localização do prestador para o servidor
        socket.emit('update-location', {
          id, // Usando o ID da URL
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude
        });
      } catch (error) {
        console.error('Erro ao obter localização do prestador:', error.message);
      }
    };

    fetchUserLocation();

    return () => {
      socket.off('location-updated');
    };
  }, [id]);


  useEffect(() => {

    const destiny = async (idcliente, idprestador) => {
      try {
        console.log(idcliente)
        const responseCliente = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/cliente/${idcliente}`);
        const responsePrestador = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/prestador/${idprestador}`)

        console.log(responsePrestador)

        if (responseCliente.data || responsePrestador.data) {
          const { latitude, longitude } = responseCliente.data;
          setCoordUser({ lat: latitude, lon: longitude });
          calculateRoute({ lat: responsePrestador.data.latitude, lng: responsePrestador.data.longitude }, { latitude, longitude }); // Exemplo de local fixo de origem
        }


      } catch (error) {
        console.error(error);
      }
    }







    const fetchService = async () => {
      try {
        const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/pedidos/${id}`);
        setService(response.data);
        console.log(response.data);
        await destiny(response.data.idcliente, response.data.idprestador)
      } catch (error) {
        console.error('Erro ao carregar os detalhes do serviço:', error);
      }
    };



    fetchService();

  }, [id]);
  
  useEffect(() => {
    if (userPosition.lat !== null && userPosition.lon !== null) {
      const latPrestador = String(userPosition.lat).split("").slice(0,6);
      const latCliente = String(coordUser.lat).split("").slice(0,6)
      const lonPrestador = String(userPosition.lon).split("").slice(0,6)
      const lonCliente = String(coordUser.lon).split("").slice(0,6)

      console.log(JSON.stringify(latPrestador) === JSON.stringify(latCliente))
      console.log(JSON.stringify(lonPrestador) === JSON.stringify(lonCliente))
      
      if (JSON.stringify(latPrestador) === JSON.stringify(latCliente) &&  JSON.stringify(lonPrestador) === JSON.stringify(lonCliente)) {
        console.log("ta caindo aqui")
        const intervalo = setInterval(() => {
          setTempo((tempoAnterior) => tempoAnterior + 1);
        }, 1000);

        return () => clearInterval(intervalo);
      }
    }

  }, []);

  console.log(tempo)
 


  useEffect(() => {
    socket.on('status-pedido-atualizado', ({ id: pedidoId, status }) => {
      if (pedidoId === Number(id) && status === 'Cancelado') {
        console.log('deu certo o useEffect  ')
        setStatus(service.status)
      }
    });

    return () => {
      socket.off('status-pedido-atualizado');
    };
  }, [id]);


  const calculateRoute = async (start, end) => {
    const url = `https://graphhopper.com/api/1/route?point=${start.lat},${start.lng}&point=${end.latitude},${end.longitude}&vehicle=car&locale=pt&key=${graphhopperKey}&points_encoded=false`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      const distance = data.paths[0].distance;
      const distanceValue = distance / 1000
      if (distanceValue > 0) {
        setPrice(50)
      }
      setPrice(distanceValue * 2 / 10 * 6 + 50)


      if (data.paths && data.paths.length > 0) {
        const routeCoordinates = data.paths[0].points.coordinates;
        const routeLatLngs = routeCoordinates.map(coord => [coord[1], coord[0]]);

        // Inicializa o mapa Leaflet
        const map = L.map('map').setView([start.lat, start.lng], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '© OpenStreetMap contributors'
        }).addTo(map);

        // Desenha a rota no mapa
        const routeLine = L.polyline(routeLatLngs, { color: 'blue' }).addTo(map);
        map.fitBounds(routeLine.getBounds());
      } else {
        console.error('Nenhuma rota encontrada na resposta:', data);
      }

    } catch (error) {
      console.error('Erro ao calcular a rota:', error);
    }
  };


  const compareDates = (date1, date2) => {
    const d1 = new Date(date1).toISOString().split('T')[0];
    const d2 = new Date(date2).toISOString().split('T')[0];
    return d1 === d2;
  };

  const handleComplete = async () => {
    if (!service) {
      alert('Serviço não encontrado.');
      return;
    }

    const currentDate = new Date();
    const serviceDate = new Date(service.date);

    if (!compareDates(currentDate, serviceDate)) {
      alert('A data atual não coincide com a data do pedido.');
      return;
    }

    try {
      await axios.post('https://appdev.chamaseuze.chamaoseuze.com.br/atualizar-pedido', {
        id: service.id,
        status: 'Concluído',
        provider: service.provider,
        idprestador: service.idprestador,
        executeTime: tempo,
        preco: price
      });

      socket.emit('status-pedido-atualizado', { id: service.id, status: 'Concluído' });

      alert('Serviço concluído!');
      navigate(`/aguardando-pagamento/${service.id}`);
    } catch (error) {
      console.error('Erro ao atualizar status do serviço:', error);
    }
  };

  if (!service) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="service-info">
      {service.status === 'Cancelado' && (
        <div className="modal">
          <div className="modal-content">
            <h2>Seu atendimento foi cancelado</h2>
            <p>Se seu atendimento foi cancelado 2 horas ou menos do prazo combinado você receberá um valor de multa que o cliente irá pagar</p>
            <button onClick={(e) => navigate(`/Principal/${service.idprestador}`)}>
              Voltar para o Menu
            </button>
          </div>
        </div>
      )}
      <h2>Serviço em Andamento</h2>
      <p><strong>Cliente:</strong> {service.cliente}</p>
      <p><strong>Serviço:</strong> {service.nome_servico}</p>
      <p><strong>Endereço:</strong> {service.endereco}, {service.numero}</p>
      <p><strong>Status:</strong> Em Andamento</p>

      <div id="map" style={{ height: '400px', width: '100%', zIndex: 0 }}></div>

      <div className="button-container">
        <button className="btn" onClick={handleComplete}>Concluir Serviço</button>
      </div>
    </div>
  );
};

export default ServiceInProgress;
