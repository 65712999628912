import axios from "axios"
import { Formik, Form, Field } from "formik"
import { useNavigate } from "react-router-dom"
import './index.css'
export const LoginAdmin = () => {
    const navigate = useNavigate()


    const handleLogin = async(values) =>{
        console.log(values)
        try {
            const response = await axios.post("https://appdev.chamaseuze.chamaoseuze.com.br/api/loginUserMaster", values)
            console.log(response)
            if(response.data.token && response.data.id){
                localStorage.setItem('authToken', response.data.token)
                localStorage.setItem("id", response.data.id)
            }

            navigate(`/Principal-Administrador/${response.data.token}/${response.data.id}`)
        } catch (error) {
            console.error(error.message || error.data)
        }       
    }
    return(
        <main style={{height:'100dvh', display:"flex", alignItems: 'center', justifyContent:"center"}}>
         <div className="login-container">
            <div className="login">
                <div className="login-content">
                    <Formik
                        initialValues={{
                            email: "",
                            password: "",
                        }}
                        onSubmit={handleLogin}
                    >
                        <Form className='form-group'>
                            <Field className='input' name='email' type='email' placeholder="Digite seu Email"/>
                            <Field className='input' name='password' type='password' placeholder="Digite sua senha"/>

                            <button
                            className="botao-login"
                            id="botaoLogin"
                            type="submit"
                            >
                            Entrar
                            </button>
                        </Form>
                    </Formik>
                </div>
            </div>
         </div>
        </main>
    )

}