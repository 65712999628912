import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from 'yup';
import axios from 'axios';
import "./cadastro.css";
import { useNavigate } from "react-router-dom";
import SimpleButton from "../../components/simplebutton";
import TermoPrestadorNaoAssinante from "./TermoPrestadorNãoAssinante";

// Função para validar CPF
const validateCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
    return false;
  }
  let sum = 0;
  let remainder;
  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(cpf.substring(9, 10), 10)) {
    return false;
  }
  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  return remainder === parseInt(cpf.substring(10, 11), 10);
};

// Método personalizado do yup para validar CPF
yup.addMethod(yup.string, 'cpf', function (message) {
  return this.test('cpf', message, function (value) {
    const { path, createError } = this;
    return (
      validateCPF(value) || createError({ path, message: message || 'CPF inválido' })
    );
  });
});

// Função para validar CEP usando a API ViaCEP
const validateCEP = async (cep) => {
  cep = cep.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
  if (cep.length !== 8) {
    return false;
  }
  try {
    const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    return !response.data.erro;
  } catch (error) {
    return false;
  }
};

// Método personalizado do yup para validar CEP
yup.addMethod(yup.string, 'cep', function (message) {
  return this.test('cep', message, function (value) {
    const { path, createError } = this;
    return validateCEP(value).then(isValid => {
      return isValid || createError({ path, message: message || 'CEP inválido' });
    });
  });
});

const CadastroP = () => {
  const [isModal, setIsModal] = useState(false)
  const navigate = useNavigate();
  const [endereco, setEndereco] = useState('');
  const [isModal2, setIsModal2] = useState(false)
  const [services, setServices] = useState([])


  const handleModal = () => {
    setIsModal((prev) => !prev);
  }
  const handleModal2 = () => {
    setIsModal2((prev) => !prev);
  }

  const handleClickCadastro = (values) => {
    console.log(values)
    axios.post('https://appdev.chamaseuze.chamaoseuze.com.br/prestadoresC', values)
      .then(response => {
        navigate('/LoginP');
      })
      .catch(error => {
        console.error('Houve um erro no cadastro:', error);
        if (error.response.data.error === 'Email já cadastrado.') {
          setIsModal2(true)
        }
      });
  };

  useEffect(() => {
    const fetchServies = async () => {
      try {

        const response = await axios.get("https://appdev.chamaseuze.chamaoseuze.com.br/todos-servicos");
        setServices(response.data);

      } catch (error) {
        console.error(error.message || error.data);
      }
    }

    fetchServies();
  }, [])

  const validationCadastro = yup.object().shape({
    nome: yup.string().required("Este campo é obrigatório"),
    email: yup.string().email().required("Este campo é obrigatório"),
    senha: yup.string().min(8, "A senha deve ter 8 caracteres").required("Este campo é obrigatório"),
    ConfirmPassword: yup.string().oneOf([yup.ref("senha"), null], "As Senhas não são iguais"),
    TermodeUso: yup.boolean().oneOf([true], "Você deve aceitar os termos de uso").required("Você deve aceitar os termos de uso"),
    cpf: yup.string().required("Este campo é obrigatório").cpf("CPF inválido"),
    cep: yup.string().required("Este campo é obrigatório").cep("CEP inválido"),
    endereco: yup.string().required("Este campo é obrigatório"),
    numeroCasa: yup.string().required("Este campo é obrigatório"),
    complemento: yup.string(),
    telefone: yup.string(),
    servico: yup.string().required("Este campo é obrigatório"),
  });

  const handleCepChange = async (e, setFieldValue) => {
    const cep = e.target.value.replace(/[^\d]+/g, '');
    setFieldValue('cep', cep);

    if (cep.length === 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        if (!response.data.erro) {
          setFieldValue('endereco', response.data.logradouro);
          setEndereco(response.data.logradouro);
        } else {
          setEndereco('');
        }
      } catch (error) {
        setEndereco('');
      }
    }
  };

  return (
    <main className="no-scroll">
      {isModal2 && (
        <div className="modal">
          <div className="modal-content">
            <h1>Email já cadastrado!</h1>
            <SimpleButton onClick={handleModal2}>Ok</SimpleButton>
          </div>
        </div>
      )}
      <Formik
        initialValues={{
          nome: '',
          email: '',
          senha: '',
          ConfirmPassword: '',
          TermodeUso: false,
          cpf: '',
          cep: '',
          endereco: '',
          numeroCasa: '',
          complemento: '',
          telefone: '',
          servico: services ? services[0] : null,
        }}
        onSubmit={handleClickCadastro}
        validationSchema={validationCadastro}
      >
        {({ setFieldValue }) => (
          <div className="form-container">
            <Form className="cadastroForm">
              <div className="form-group">
                <label>Nome Completo</label>
                <Field
                  name="nome"
                  placeholder="Nome Completo"
                  className="input texto-laranja"
                />
                <ErrorMessage
                  component={"span"}
                  name="nome"
                  className="formerro" />
              </div>

              <div className="form-group">
                <label>CPF</label>
                <Field
                  name="cpf"
                  placeholder="CPF"
                  className="input texto-laranja"
                />
                <ErrorMessage
                  component={"span"}
                  name="cpf"
                  className="formerro" />
              </div>

              <div className="form-group">
                <label>CEP</label>
                <Field
                  name="cep"
                  placeholder="CEP"
                  className="input texto-laranja"
                  onChange={(e) => handleCepChange(e, setFieldValue)}
                />
                <ErrorMessage
                  component={"span"}
                  name="cep"
                  className="formerro" />
              </div>

              <div className="form-group">
                <label>Endereço</label>
                <Field
                  name="endereco"
                  placeholder="Endereço"
                  className="input texto-laranja"
                  value={endereco}
                />
                <ErrorMessage
                  component={"span"}
                  name="endereco"
                  className="formerro" />
              </div>

              <div className="form-group">
                <label>Número da Casa</label>
                <Field
                  name="numeroCasa"
                  placeholder="Número da Casa"
                  className="input texto-laranja"
                />
                <ErrorMessage
                  component={"span"}
                  name="numeroCasa"
                  className="formerro" />
              </div>

              <div className="form-group">
                <label>Complemento</label>
                <Field
                  name="complemento"
                  placeholder="Complemento"
                  className="input texto-laranja"
                />
                <ErrorMessage
                  component={"span"}
                  name="complemento"
                  className="formerro" />
              </div>

              <div className="form-group">
                <label>Telefone Celular</label>
                <Field
                  name="telefone"
                  placeholder="Telefone Celular"
                  className="input texto-laranja"
                />
                <ErrorMessage
                  component={"span"}
                  name="telefone"
                  className="formerro" />
              </div>

              <div className="form-group">
                <label>Serviço Prestado</label>
                <Field
                  as="select"
                  name="servico"
                  className="input texto-laranja"
                >
                  {services.map((service) => (
                    <option value={service.nome_servico} >{service.nome_servico}</option>
                  ))}
                </Field>
                <ErrorMessage
                  component={"span"}
                  name="servico"
                  className="formerro" />
              </div>

              <div className="form-group">
                <label>E-mail</label>
                <Field
                  name="email"
                  placeholder="E-mail"
                  className="input texto-laranja"
                />
                <ErrorMessage
                  component={"span"}
                  name="email"
                  className="formerro" />
              </div>

              <div className="form-group">
                <label>Senha</label>
                <Field
                  name="senha"
                  placeholder="Senha"
                  className="input texto-laranja"
                  type="password"
                />
                <ErrorMessage
                  component={"span"}
                  name="senha"
                  className="formerro" />
              </div>

              <div className="form-group">
                <label>Confirme sua Senha</label>
                <Field
                  name="ConfirmPassword"
                  placeholder="Confirme sua Senha"
                  className="input texto-laranja"
                  type="password"
                />
                <ErrorMessage
                  component={"span"}
                  name="ConfirmPassword"
                  className="formerro" />
              </div>

              <div className="form-group">
                <label>Termos e condições de uso</label>
                <Field
                  type="checkbox"
                  name="TermodeUso"
                  id="termos"
                />
                <ErrorMessage
                  component={"span"}
                  name="TermodeUso"
                  className="formerro" />
              </div>

              <div className="form-group text-center">
                <SimpleButton onClick={handleModal}>Ver os Termos de Uso</SimpleButton>
              </div>

              {isModal && (
                <div className="modal" style={{ alignItems: 'baseline' }}>
                  <div className="modal-content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <div className="termos">
                      <TermoPrestadorNaoAssinante />
                    </div>
                    <SimpleButton onClick={handleModal} style={{ width: '200px' }}>Concluir</SimpleButton>
                  </div>
                </div>
              )}

              <button className="btn-cadastro" type="submit">
                Cadastrar
              </button>
            </Form>
          </div>
        )}
      </Formik>
    </main>
  );
};

export default CadastroP;