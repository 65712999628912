// src/components/ServicesManagement.js

import React, { useEffect, useState } from 'react';
import './ServicesManagement.css'; // Arquivo CSS para estilos
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import { BackButtonAdmin } from '../components/BackButton.jsx/BackButton';

const ServicesManagement = () => {
  const [services, setServices] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchServies = async () => {
      try {

        const response = await axios.get("https://appdev.chamaseuze.chamaoseuze.com.br/todos-servicos");
        setServices(response.data);

      } catch (error) {
        console.error(error.message || error.data);
      }
    }

    fetchServies();
  }, [])

  const handleInclude = () => {
    setShowModal(true);
  };

  const handleAddService = async (values) => {
    try {
      console.log(values);
      const response = await axios.post("https://appdev.chamaseuze.chamaoseuze.com.br/criar-servico", values)
      console.log(response)
    } catch (error) {
      console.error(error)
    }
  };

  const handleEdit = () => {
    // Função para editar um serviço
    alert('Funcionalidade de edição não implementada.');
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`https://appdev.chamaseuze.chamaoseuze.com.br/excluir-servico/${id}`)
      console.log(response)
    } catch (error) {
      console.error(error)
    }

  };

  return (
    <div className="services-management">

      <header>
        <h1>Gestão de Serviços</h1>
      </header>
      <BackButtonAdmin />
      <main>
        <div className="header-buttons">
          <button onClick={() => setShowModal(true)}>Adicionar Serviço</button>
        </div>
        <section className="services-list">
          <table>
            <thead>
              <tr>
                <th>opções</th>
                <th>Nome</th>
                <th>Categoria</th>
                <th>Valor Médio</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service) => (
                <tr key={service.id}>
                  <td><p style={{ backgroundColor: "red", fontSize: "14px", width: "30%", borderRadius: '5px', textAlign: 'center', color: "white", padding: '5px' }} onClick={() => handleDelete(service.id)} >Excluir</p></td>
                  <td>{service.nome_servico}</td>
                  <td>{service.categoria}</td>
                  <td>{service.preco}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <Formik
                initialValues={{
                  nome_servico: '',
                  categoria: '',
                  preco: '',
                  tipo: 'não_assinante',
                }}
                onSubmit={handleAddService}
              >
                <Form>
                  <h2>Incluir Serviço</h2>

                  <label>
                    Nome:
                    <Field
                      type="text"
                      name='nome_servico'
                    />
                  </label>

                  <label>
                    Categoria:
                    <Field
                      type="text"
                      name='categoria'

                    />
                  </label>

                  <label>
                    Tipo:
                    <Field as='select' name='tipo' >
                      <option value="assinante">Assinante</option>
                      <option value="não_assinante">Não Assinante</option>
                    </Field>
                  </label>

                  <label>
                    Valor Médio:
                    <Field
                      type="text"
                      name="preco"
                    />
                  </label>

                  <div className="modal-buttons">
                    <button type='submit' >Adicionar</button>
                    <button type='button' onClick={() => setShowModal(false)}>Cancelar</button>
                  </div>

                </Form>
              </Formik>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default ServicesManagement;